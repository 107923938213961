import Cookies from "@ewt/sls-web-track/src/third/js.cookie";
import { browserCompatibilityLogger } from "../logger";
import "./index.scss";
import { checkLowerIE, checkOtherBrowser } from "../utils";

/**
 * 浏览器下载信息集合
 * 包含：浏览器图标、浏览器名称、下载地址
 */
const BROWSER_DOWNLOAD_IMG_PREFIX =
	"//web.ewt360.com/common/customer/www/browserUpdate";
const BROWSER_DOWNLOAD_LIST = [
	{
		browserImg: `${BROWSER_DOWNLOAD_IMG_PREFIX}/browser_chrome.png`,
		title: "Chrome浏览器",
		// address:
		// 	"https://cdn.ewt360.com/resources/common/static/js/general-capabilities/high-availability/installer/Chrome-124.exe",
    address: 'https://si.ewt360.com/resources/common/chrome/109.0.5414.120_chrome_installer.exe',
	},
	{
		browserImg: `${BROWSER_DOWNLOAD_IMG_PREFIX}/browser_360.png`,
		title: "360浏览器",
		address: "https://browser.360.cn/ee/",
	},
];

// 更新浏览器标识 - 旧版的
const UPGRADE_YOUR_BROWSER_FLAG = "upgrade_your_browser_flag";
// 提升弹窗/自动返回旧版的间隔时长，24小时
const timeout: Date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

/**
 * @description 升级提示核心类
 */
export class UpgradeYourBrowser {
	public constructor() {
		const beta: boolean =
			this.getQueryVariable("debug") ||
			((checkLowerIE() || checkOtherBrowser()) &&
				!this.checkIsMobilePlatform());
		if (beta) {
			try {
				// 出现浏览器升级提示时上报点位，统计出现频率
				browserCompatibilityLogger?.warn({
					description: "[不符合兼容性标准]",
					userAgent: window.navigator.userAgent,
					checkLowerIE: checkLowerIE(),
					checkOtherBrowser: checkOtherBrowser(),
					checkIsMobilePlatform: this.checkIsMobilePlatform(),
          eventType: "browser-compatibility-failed",
				});
			} catch (error) {
				console.log("sls上报失败", error);
			}
			this.stepUpdate();
		}
	}

	// 检测是否为移动端浏览器
	private checkIsMobilePlatform(): boolean {
		const reg =
			/(phone|pad|pod|ios|android|mobile|blackberry|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian)/gi;
		return !!window.navigator.userAgent.toLocaleLowerCase().match(reg);
	}

	// 判断地址栏目参数：debug 调试模式
	private getQueryVariable(keyName: string): boolean {
		const {
			location: { search },
		} = window;
		const queryStr = search.substring(1) || window.location.hash.split("?")[1]; //
		return !!(queryStr && queryStr.indexOf(keyName) > -1);
	}

	private stepUpdate(): boolean {
		const res: any = Cookies.get(UPGRADE_YOUR_BROWSER_FLAG);
		const url = window.location.href;
		const isVideoPage = url.indexOf("playVideo") === -1; // 判断当前页面是否非e讲堂播放页面
		if (res && isVideoPage) {
			return false;
		}
		const myEle = document.createElement("div"); // 创建最外层父容器Node节点，以便于append
		myEle.id = "upgrade_your_browser_container"; // 父容器id标识，增加搜索速度，区分其他业务标识
		myEle.className = "upgrade_your_browser_container";

		let browserBox = "";
		for (let i = 0; i < BROWSER_DOWNLOAD_LIST.length; i++) {
			const item = BROWSER_DOWNLOAD_LIST[i];
			browserBox += `<li class="browser_item" style="${
				i % 2 !== 0 ? "margin-right: 0" : ""
			}">
        <a href="${item.address}" target="_blank">
          <img class="browser_img" src="${item.browserImg}" />
          <span class="browser_title">${item.title}</span>
          <img class="browser_download_img" src="//web.ewt360.com/common/browserUpdate/browser_down.png" />
        </a>
      </li>`;
		}

		myEle.innerHTML = `
      <div class="upgrade_your_browser_content">
        <img id="cancelV" src="//web.ewt360.com/common/customer/www/browserUpdate/browser_colse_white.png" alt="close_img" class="broser_close" />
        <img src="//web.ewt360.com/common/browserUpdate/browser_update_top.png" alt="低版本提示图" />
        <div class="recommend_browser_info">
          <p class="recommend_text">推荐使用：</p>
          <ul class="browser_list" style="margin-top: 10px;">
            ${browserBox}
          </ul>
        </div>
      </div>`;
		if (
			typeof window.showUpdateBrowserModal === "undefined" ||
			window.showUpdateBrowserModal
		) {
			document.body.appendChild(myEle);
			// 绑定browser_item元素的click事件
			const browserItems: any = document.getElementsByClassName("browser_item");
			for (let i = 0; i < browserItems.length; i++) {
				browserItems[i].onclick = () => {
					browserCompatibilityLogger.info({
						description: "[用户操作] 点击浏览器下载",
            eventType: "browser-compatibility-install-click"
					});
				};
			}

			const cancelElement: any = document.getElementById("cancelV"); // 关闭按钮绑定事件
			cancelElement.onclick = () => {
				browserCompatibilityLogger.info({
					description: "[用户操作] 关闭浏览器升级提示",
          eventType: "browser-compatibility-close-update"
				});
				myEle.style.visibility = "hidden";
				Cookies.set(UPGRADE_YOUR_BROWSER_FLAG, "true", { expires: timeout }); // 设置此提示有效期为1天
				document.body.removeChild(myEle); // 删除当前子节点
			};
		}
		return true;
	}
}
