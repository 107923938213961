import { poorNetworkLogger } from "../logger";
import {
	ApplicationLoadEventKey,
	ApplicationLoadTimeoutKey,
	getIOSVersion,
	getTemplateId,
	isAppVersionGte,
	isIOS,
	safeJSONStringify,
} from "../utils";
import { whiteScreenDetector } from "../white-screen";

/** 模板节点ids */
const templateIds = getTemplateId();
// const templateIds = ['test1', 'test2']
/** 是否在mst app环境 */
const isInMstApp = window?.mstJsBridge?.isInMstApp();
/** 加载记录缓存关键字 */
const LoadStorageKey = "loadRecord";
/** 错误页面提示文案 */
const ErrorMessage = "加载失败，请检查网络后重试";
/** 最小加载时间 */
const minLoadDuration = 500;
/** 最大监听加载时间 */
const maxLoadDuration = 60000;
/** 移动端支持版本 */
const supportVersion = "10.4.5";
/** 白屏检测切片数 */
const whiteScreenSliceTotal = window?.whiteScreenSliceTotal ||  10;
/** 绘制的loading节点 */
let loadingNode: any;
/** 开始时间 */
let startTime: number;
let intervalListener: any; // 节点监听定时器
let errorTimer: any; // 异常定时器

let isLoaded = false; // 是否html load
/** 升级x5回调方法 */
let updateX5Fn: any;
/** 升级x5等待时间 */
let waitToUpdateX5: number;

const logger = poorNetworkLogger;
export const loadingIds = [
	"loading-container",
	"loading-content",
	"loading-icon-container",
	"loading-icon-people",
	"loading-icon-book",
	"loading-text",
];

/**
 * 检查页面是否渲染
 * @returns 页面是否渲染
 */
function checkPageRender() {
	return isLoaded && !whiteScreenDetector(whiteScreenSliceTotal, [...templateIds, ...loadingIds]);
}

/** 展示错误页面 */
function renderError() {
	try {
		logger?.info({
			description: "[错误页面] 开始绘制h5 错误页面",
			eventType: "poor-network-h5-error-page-start-log",
		});
		const isReload = false;
		const containerElem = document.createElement("div");
		const contentElem = document.createElement("div");
		const iconElem = new Image();
		const textElem = document.createElement("p");
		const btnElem = document.createElement("div");
		containerElem.setAttribute(
			"style",
			"position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: #fff;",
		);
		contentElem.setAttribute(
			"style",
			"position: absolute;top: 43%;left: 50%;transform: translate(-50%, -50%);",
		);
		iconElem.setAttribute("style", "width: 160px; height: 160px;");
		iconElem.src = "https://file.ewt360.com/file/1679223653145280967";
		iconElem.onerror = () => {
			iconElem.remove();
			logger?.warn({
				description: "[错误页面] 错误页面图片加载失败",
				eventType: "poor-network-h5-error-page-icon-error",
			});
		};
		textElem.setAttribute(
			"style",
			"margin: 8px 0 0; font-size: 14px; color: #A7ACB9; text-align: center;",
		);
		textElem.innerText = ErrorMessage;
		btnElem.setAttribute(
			"style",
			"margin: 12px auto; width: 116px;background: #2E86FF;border-radius: 100px;border-radius: 32px;font-size: 16px;line-height: 32px;text-align: center; color: #fff;",
		);
		btnElem.innerText = "重新加载";
		btnElem.onclick = () => {
			if (isReload) return;
			window.location.reload();
			containerElem.remove();
			document.body.appendChild(loadingNode);
		};

		contentElem.appendChild(iconElem);
		contentElem.appendChild(textElem);
		contentElem.appendChild(btnElem);
		containerElem.appendChild(contentElem);

		document.body.appendChild(containerElem);
		logger?.info({
			description: "[错误页面] h5错误页面绘制完成",
			eventType: "poor-network-h5-error-page-success-error",
		});
	} catch (e) {
		// slsErrorLog('错误页面渲染失败')
		logger?.error({
      response: e,
			description: "[错误页面] h5错误页面渲染失败",
			eventType: "poor-network-h5-error-page-fail-error",
		});
	}
}

/**
 * 展示错误页面
 */
function showErrorPage() {
	if (!isInMstApp) {
		// 若不在app环境
		renderError();
	} else {
		logger?.info({
			description: "[错误页面] 开始通知app展示失败页面",
			eventType: "poor-network-app-error-page-start-log",
		});
		// 通知APP展示失败页面
		window?.mstJsBridge
			?.getNativeData({
				domain: "web",
				action: "showError",
				params: {
					msg: ErrorMessage,
				},
			})
			.then((res: any) => {
				if (res?.code === 200) {
					logger?.info({
						description: "[错误页面] 展示app错误页面成功",
						eventType: "poor-network-app-error-page-success-log",
					});
				} else {
					renderError();
					logger?.error({
            response: res,
						description: "[错误页面] 通知app展示错误页面失败",
						eventType: "poor-network-app-error-page-fail-error",
					});
				}
			});
	}
}

/**
 * 移除h5内的loading节点
 */
function removeLoading() {
	if (loadingNode) {
		// 从根节点上移除loading节点
		loadingNode.remove();
		// recordTime('移除h5loading');
		logger?.info({
			description: "[关闭loading] 移除h5loading",
			eventType: "poor-network-close-h5-loading-log",
		});
	}
}

/** 关闭appLoading */
function closeAppLoading() {
	logger?.info({
		description: "[关闭loading] 开始通知app关闭loading",
		eventType: "poor-network-close-app-loading-start-log",
	});
	// 通知APP关闭Loading
	window?.mstJsBridge
		?.getNativeData({
			domain: "web",
			action: "showLoading",
			params: {
				show: false,
			},
		})
		.then((res: any) => {
			if (res?.code === 200) {
				logger?.info({
					description: "[关闭loading] 通知app关闭加载页成功",
					eventType: "poor-network-close-app-loading-success-log",
				});
			} else {
				logger?.error({
          response: res,
					failCode: res?.code,
					description: "[关闭loading]通知app关闭加载状态失败",
					eventType: "poor-network-close-app-loading-error",
				});
				removeLoading();
			}
		});
}

/**
 * 关闭loading
 */
async function closeLoading() {
	const timestamp = new Date().getTime();
  // 移动端10.4.5版本以上支持弱网加载
  const isSupportWeaknet = await isAppVersionGte(supportVersion, true);
	localStorage.setItem(
		LoadStorageKey,
		JSON.stringify({
			time: timestamp,
			duration: timestamp - startTime,
		}),
	);
	// 根据是否有loading节点，来判断是否需要进行loading移除
	// 情形：在app环境，接管未成功，h5绘制loading
	removeLoading();

	if (isInMstApp && isSupportWeaknet) {
		// 通知app关闭loading
		closeAppLoading();
	}
}

/**
 * 监听react渲染树是否开始挂载
 */
const mountedListener = () =>
	new Promise((resolve, reject) => {
		console.log("开始监听", new Date().getTime());

    if (document.readyState === "complete") {
      // html 加载完成
      isLoaded = true;
    } else {
      window.addEventListener("load", () => {
        isLoaded = true;
        logger?.info({
          description: "[加载监控] html load完成",
          eventType: "poor-network-monitor-load-complete-log",
        });
      });
    }

		// 采用定时器监听
		intervalListener = setInterval(() => {
			if (checkPageRender()) {
				// recordTime('监听节点已更新');
				logger?.info({
					description: "[加载监控] 检测到页面已渲染",
					eventType: "poor-network-monitor-load-complete-log",
				});
				closeLoading();

				// 关闭监听
				clearInterval(intervalListener);
				intervalListener = null;

				// 清除异常定时器
				clearTimeout(errorTimer);
				window.dispatchEvent(new Event(ApplicationLoadEventKey));
				resolve(true);
				return;
			}
			// 若load后等待n秒后，页面还是白屏，则升级x5
			if (isLoaded && updateX5Fn) {
				if (waitToUpdateX5 > 0) waitToUpdateX5 -= 50;
				else {
					logger?.info({
						description: "[兼容性] 系统不兼容，准备进入升级x5",
						eventType: "poor-network-updatex5-log",
					});
					closeLoading();
					updateX5Fn();
					// 关闭监听
					clearInterval(intervalListener);
					intervalListener = null;

					// 清除异常定时器
					clearTimeout(errorTimer);
					window.dispatchEvent(new Event(ApplicationLoadEventKey));
				}
			}
		}, 50);
		// recordTime('开始interval监听');

		// 一分钟后，若还无新增节点，则按异常情况处理
		errorTimer = setTimeout(() => {
			closeLoading();
			if (!checkPageRender()) {
				// slsErrorLog('超时，监听节点还未更新');
				logger?.error({
					description: "[加载监控] 监控超时，页面还未渲染",
					eventType: "poor-network-monitor-timeout-white-screen-error",
				});
				showErrorPage();
			} else {
				logger?.warn({
					description: "[加载监控] 监控超时，页面已经渲染",
					eventType: "poor-network-monitor-timeout-page-rendered-info",
				});
				resolve(true);
			}

			if (intervalListener) {
				clearInterval(intervalListener);
				intervalListener = null;
			}
			window.dispatchEvent(new Event(ApplicationLoadTimeoutKey));
		}, maxLoadDuration);
	});

/** 接管android loading */
function controlAndroidLoading() {
	if (window?.android?.controlLoading()) {
		logger?.info({
			description: "[通知app接管] android app接管控制权成功",
			eventType: "poor-network-android-control-success-log",
		});
	} else {
		logger?.warn({
			failReason: window?.android?.controlLoading
				? "接管控制权方法接口为false"
				: "接管控制权方法不存在",
			description:
				"[通知app接管] android app接管控制权失败，开始走h5 loading流程",
			eventType: "poor-network-android-control-fail-error",
		});
		if (precondition()) {
			renderLoading();
		}
	}
}

/** 接管ios loading */
function controlIOSLoading() {
	if (window?.webkit?.messageHandlers?.controlLoading?.postMessage) {
		const IOSVersion = getIOSVersion();
		const promise =
			window?.webkit?.messageHandlers?.controlLoading?.postMessage("");
		// ios版本>14，才有结果返回
		if (IOSVersion && IOSVersion >= 14) {
			promise.then((res: any) => {
				if (res !== 1) {
					logger?.error({
						description:
							"[通知app接管] ios接管控制权结果为false，接管控制权失败，开始进入h5 loading流程",
						eventType: "poor-network-ios-control-fail-error",
            response: res
					});
					if (precondition()) {
						renderLoading();
					}
          return;
				}
        logger?.info({
          description: "[通知app接管] ios app接管控制权成功",
          eventType: "poor-network-ios-control-success-log",
        });
			});
      return;
		} 
    logger?.info({
      description: "[通知app接管] ios低版本，通知ios app接管控制权",
      eventType: "poor-network-ios-control-success-log",
    });
	} else {
		logger?.warn({
			description: "[通知app接管] 通知ios app接管控制权方法不存在",
			eventType: "poor-network-ios-control-noexist-error",
		});
		if (precondition()) {
			renderLoading();
		}
	}
}

/**
 * 进行h5内loading的绘制挂载
 */
function renderLoading() {
	document.addEventListener("DOMContentLoaded", () => {
		try {
			const containerElem = document.createElement("div");
			const contentElem = document.createElement("div");
			const iconContainerElem = document.createElement("div");
			const peopleElem = new Image();
			const bookElem = new Image();
			const textElem = document.createElement("p");
			containerElem.id = "loading-container";
			containerElem.setAttribute(
				"style",
				"position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: #fff;transform: translateZ(0);",
			);
			contentElem.id = "loading-content";
			contentElem.setAttribute(
				"style",
				"position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);",
			);
			iconContainerElem.id = "loading-icon-container";
			iconContainerElem.setAttribute(
				"style",
				"position: relative; width: 100px; height: 100px;",
			);
			peopleElem.id = "loading-icon-people";
			peopleElem.setAttribute("style", "width: 100px; height: 100x;");
			peopleElem.src = "https://file.ewt360.com/file/1679227424126558747";
			peopleElem.onerror = () => {
				logger?.warn({
					description: "[页面渲染] loading页面渲染，树人图片加载失败",
					eventType: "poor-network-loading-people-img-error",
				});
				iconContainerElem.remove();
			};
			bookElem.id = "loading-icon-book";
			bookElem.setAttribute(
				"style",
				"position: absolute; top: 64px; left: 0; width: 100px; height: 36px;",
			);
			bookElem.src = "https://file.ewt360.com/file/1679227664644719138";
			bookElem.onerror = () => {
				logger?.warn({
					description: "[页面渲染] loading页面渲染，加载书本图片加载失败",
					eventType: "poor-network-loading-book-img-error",
				});
				iconContainerElem.remove();
			};
			bookElem.onload = () => {
				// 若不支持animate，则静态展示
				if (bookElem?.animate) {
					bookElem?.animate(
						[
							{ transform: "translateY(0px)" },
							{ transform: "translateY(10px)" },
							{ transform: "translateY(0px)" },
						],
						{
							duration: 800,
							easing: "ease-in-out",
							iterations: Number.POSITIVE_INFINITY,
						},
					);
				}
			};

			textElem.id = "loading-text";
			textElem.setAttribute(
				"style",
				"margin: 18px 0 0; font-size: 14px; color: #A7ACB9; text-align: center;",
			);
			textElem.innerText = "加载中…";

			iconContainerElem.appendChild(peopleElem);
			iconContainerElem.appendChild(bookElem);
			contentElem.appendChild(iconContainerElem);
			contentElem.appendChild(textElem);
			containerElem.appendChild(contentElem);
			loadingNode = containerElem;
			document.body.appendChild(containerElem);
		} catch (e) {
			// loading页渲染失败
			logger?.error({
				description: "[页面渲染] loading页渲染失败",
				eventType: "poor-network-loading-render-error",
			});
		}
	});
}

/**
 * 前置判断条件，是否需要显示loading
 */
function precondition() {
	// 若chrome版本小于45，不支持elementsFromPoint方法，则不显示loading
	if (typeof document.elementsFromPoint !== "function") {
		logger?.warn({
			description:
				"[弱网前置判断] 浏览器不支持elementsFromPoint方法, 不显示loading",
			eventType: "poor-network-precondition-page-rendered-log",
		});
		return false;
	}
	// 若页面已开始渲染
	if (checkPageRender()) {
		logger?.info({
			description: "[弱网前置判断] 未在app端，前值判断页面已经开始渲染",
			eventType: "poor-network-precondition-page-rendered-log",
		});
		return false;
	}

	try {
		// 根据缓存中的上次加载速度判断
    const lastRecordValue = localStorage.getItem(LoadStorageKey)
		const lastRecord = lastRecordValue ? JSON.parse(lastRecordValue) : '';
		const timestamp = new Date().getTime();
		const dayTime = 24 * 60 * 60 * 1000;
		// 若首次加载 | 距离上次加载超过1天 | 上次加载时间超过500ms 则显示loading
		if (
			!lastRecord ||
			lastRecord?.time - timestamp > dayTime ||
			lastRecord?.duration > minLoadDuration
		) {
			logger?.info({
				lastRecord: safeJSONStringify(lastRecord),
				description: "[弱网前置判断] 根据上次加载时间，判断当前网速过低",
				eventType: "poor-network-last-connection-slow-log",
			});
			return true;
		}
	} catch (e) {
		// 前置判断失败： e
		logger?.error({
			failReason: safeJSONStringify(e),
			description: "[弱网前置判断] 前置判断失败",
			eventType: "poor-network-precondition-error",
		});
	}
}

/**
 * 弱网前置加载处理
 * @param updateX5 升级x5的回调，若兼容性符合，则为空
 * @param renderDelayTime load后等待时间，若超过设置时间，
 */
export function poorNetwork(updateX5?: () => void, renderDelayTime = 5500) {
	// 记录开始时间
	startTime = new Date().getTime();
	logger?.info({
		startTime: startTime,
		description: "[弱网前置判断] 开始进入弱网处理",
		eventType: "poor-network-start-log",
    network: safeJSONStringify({ // 网络相关数据
      downlink: navigator?.connection?.downlink,
      effectiveType: navigator?.connection?.effectiveType,
      rtt: navigator?.connection?.rtt,
      lastLoadRecord: localStorage.getItem(LoadStorageKey)
    }),
	});

	// 判断是否在APP环境
	if (!isInMstApp) {
		if (precondition()) {
			renderLoading();
		} else {
			logger?.info({
				description: "[弱网前置判断] 网速还行",
				eventType: "poor-network-precondition-ok-log",
			});
		}
	} else {
		logger?.info({
			description: "[通知app接管] 开始通知app接管控制权",
			eventType: "poor-network-app-control-start-log",
		});
		updateX5Fn = updateX5;
		waitToUpdateX5 = renderDelayTime;
		if (!isIOS()) {
			controlAndroidLoading();
		} else {
			controlIOSLoading();
		}
	}
	mountedListener();
}

/** 提供外部关闭loading的全局方法 */
function globalCloseLoading() {
	closeLoading();

	if (intervalListener) {
		clearInterval(intervalListener);
		intervalListener = null;
	}
	// 清除异常定时器
	if (errorTimer) {
		clearTimeout(errorTimer);
		errorTimer = null;
	}
}

window.preloading = {
	close: globalCloseLoading,
};
