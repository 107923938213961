/**
 * 是否为aios系统
 * @returns
 */
export function isIOS() {
	return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

/** 获取ios版本号 */
export function getIOSVersion() {
	const str = navigator.userAgent.toLowerCase();
	const ver = str.match(/cpu iphone os (.*?) like mac os/)?.[1];
	if (ver) {
		try {
			return Number(ver.split("_")?.[0]);
		} catch (e) {
			//
		}
	}
}

/**
 * 是否为android系统
 * @returns
 */
export function isAndroid() {
	return (
		navigator.userAgent.indexOf("Android") > -1 ||
		navigator.userAgent.indexOf("Adr") > -1
	);
}

/**
 * 判断android版本是否支持
 * @returns
 */
export function getAndroidVersion() {
	const userAgent = navigator.userAgent.toLowerCase();
	const match = userAgent.match(/android\s([0-9.]+)/);
	const version = match?.[1];
	if (version) {
		try {
			return Number(version.split(".")[0]);
		} catch (e) {
			//
		}
	}
}

/**
 * 获取chrome版本
 * @returns
 */
export function getChromeVersion() {
	const userAgent = navigator.userAgent.toLowerCase();
	const match = userAgent.match(/chrome\/([\d.]+)/);
	const version = match?.[1];
	if (version) {
		try {
			return Number(version.split(".")[0]);
		} catch (e) {
			//
		}
	}
}

/**
 * 判断当前 App 版本是否大于某个版本号 `version`
 * @param {String} version 要比对的版本号
 * @param {Boolean} isEqual 是否包含等于该版本
 */
export const isAppVersionGte = async (version: string, isEqual = true) => {
  const response = await window.mstJsBridge?.getAppVersion();
  const appVersion = response?.data?.version;

  // 用来对比的版本号
  const targetAppArr = String(version).split('.');
  const targetAppMajorVersion = Number(targetAppArr && targetAppArr[0]);
  const targetAppMinorVersion = Number(targetAppArr && targetAppArr[1]);
  const targetAppPatchVersion = Number(targetAppArr && targetAppArr[2]);

  // 当前App的版本号
  const appVersionArr = String(appVersion).split('.');
  const appMajorVersion = Number(appVersionArr && appVersionArr[0]);
  const appMinorVersion = Number(appVersionArr && appVersionArr[1]);
  const appPatchVersion = Number(appVersionArr && appVersionArr[2]);
  if (appMajorVersion > targetAppMajorVersion) {
    // 当前版本主版本号大于目标版本号
    return true;
  }
  if (appMajorVersion === targetAppMajorVersion) {
    // 当主版本号相等时，再比较子版本号
    if (appMinorVersion > targetAppMinorVersion) {
      return true;
    }
    if (appMinorVersion === targetAppMinorVersion) {
      // 当子版本号也相等时，比较最末级的版本号
      if (appPatchVersion > targetAppPatchVersion) {
        return true;
      }
    }
  }
  if (isEqual && appVersion === version) {
    // 版本号完全相等时
    return true;
  }
  return false;
};


export const safeJSONStringify = (obj: any) => {
	try {
		return JSON.stringify(obj);
	} catch (e) {
		return "";
	}
};

const checkVersionsMap = {
	chrome: 61,
	firefox: 60,
	safari: 12,
};

// 检测浏览器是否低于目标版本（目标版本及以上均为true，否则为false）
function checkBrowserisLowVersionByRule(
	reg: RegExp,
	versionNum: number,
): boolean {
	const matchs = window.navigator.userAgent.toLocaleLowerCase().match(reg);
	const browserVersion = matchs?.[2] ? Number.parseInt(matchs[2], 10) : "";
	return !browserVersion || browserVersion < versionNum;
}

// 检测chrome、firefox、safari浏览器版本
export function checkOtherBrowser(): boolean {
	const userAgentStr = window.navigator.userAgent.toLocaleLowerCase() || "";
	const isAndroid = /android/i.test(userAgentStr); // 是否安卓
	const isFF = /firefox/i.test(userAgentStr); // 检测火狐
	const isEdge = /edg/i.test(userAgentStr); // 检测edge
	const isChrome =
		!isEdge && (/chrome/i.test(userAgentStr) || /crios/i.test(userAgentStr));
	const isSafari =
		/safari/i.test(userAgentStr) && !isChrome && !isAndroid && !isEdge;
	// 如果是chrome，则判断chrome版本是否小于61
	if (
		isChrome &&
		checkBrowserisLowVersionByRule(
			/(chrome|crios)\/(\d+)/,
			checkVersionsMap.chrome,
		)
	) {
		return true;
	}
	// 如果火狐浏览器，则判断版本号是否小于60
	if (
		isFF &&
		checkBrowserisLowVersionByRule(/(firefox)\/(\d+)/, checkVersionsMap.firefox)
	) {
		return true;
	}
	// 如果safari浏览器，则判断版本号是否小于11
	return (
		isSafari &&
		checkBrowserisLowVersionByRule(/(version)\/(\d+)/, checkVersionsMap.safari)
	);
}

export function checkLowerIE(): boolean {
	const userAgent = window.navigator.userAgent.toLowerCase(); // 所有字母进行小写处理
	const isIE =
		userAgent.indexOf("compatible") > -1 && userAgent.indexOf("msie") > -1; // 判断是否IE<11浏览器
	const isIE11 =
		userAgent.indexOf("trident") > -1 && userAgent.indexOf("rv:11.0") > -1; // 判断浏览器是否为ie11
	return isIE || isIE11;
}

/**
 * 取两个数组的交集
 * @param a 数组a
 * @param b 数组b
 * @returns
 */
export const intersect = (a: string[] = [], b: string[] = []) =>
	a.filter((v) => b.indexOf(v) > -1);

export const genURLByCurrentScript = (url: string) => {
	const script = document.currentScript as HTMLScriptElement;
	const src = script.src;
	const lastSlashIndex = src.lastIndexOf("/");
	const prefix = src.slice(0, lastSlashIndex + 1);
	return prefix + url;
};

/**
 * 获取默认域名列表用于检测网络状态
 */
export const getDefaultDomainList = () => {
	return [
		"si.ewt360.com/checkstatus",
		"gateway.ewt360.com/checkstatus",
		"vod01.ewt360.com/a087636d98a171eea31a1776b3ce0402/snapshots/f5b3057bb09546509b3cedbcead262af-00005.jpg",
		"vod02.ewt360.com/4b8b0527vodtransbj1252212994/08a5ea783270835013546076160/coverBySnapshot/coverBySnapshot_10_0.jpg",
		"bfe.ewt360.com/checkstatus",
		"cdn.ewt360.com/checkstatus",
		"clog.ewt360.com/checkstatus",
	];
};

export const getDomain = (): string[] => {
	try {
		// 从window的全局变量中获取
		const windowDomain = window.domainForCheckStatus;
		// 为了兼容性使用正则从URL中获取domainForCheckStatus
		const url = window.location.href;
		const reg = /domainForCheckStatus=([^&]*)/;
		const match = url.match(reg);
		const urlDomain = match ? match[1] : "";
		if (urlDomain) {
			return [urlDomain];
		}
		return windowDomain || [];
	} catch (e) {
		return [];
	}
};

export const getSrcFromScriptOnerror = (e: Event | string) => {
	try {
		if (typeof e === "string") {
			return e;
		}
		const target = e.target as HTMLScriptElement;
		return target.src;
	} catch (e) {
		return "获取script标签src失败";
	}
};

export const isScriptLoadError = (e: ErrorEvent) => {
	return (e?.target as HTMLScriptElement)?.tagName === "SCRIPT";
};

export const getTemplateId = () => {
	const script = document.currentScript as HTMLScriptElement;
	return (script ? script.getAttribute("root") || "mst-app" : "mst-app").split(
		" ",
	);
};

export const ApplicationLoadEventKey = "application-loaded";
export const ApplicationLoadTimeoutKey = "application-load-timeout";
