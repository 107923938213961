import { whiteScreenLogger } from "../logger";
import { loadingIds } from "../poor-network";
import {
	ApplicationLoadEventKey,
	ApplicationLoadTimeoutKey,
	getSrcFromScriptOnerror,
	getTemplateId,
	intersect,
	isScriptLoadError,
	safeJSONStringify,
} from "../utils";

const whiteScreenSliceTotal = window?.whiteScreenSliceTotal ||  10;

const logger = whiteScreenLogger;

/**
 * 检测点位是否有内容
 * @param x 检测点的x轴坐标
 * @param y 检测点的y轴坐标
 * @param templateIds 需要过滤的节点id数组
 * @returns 是否有内容
 */
function checkPoint(x: number, y: number, templateIds: string[]) {
	// html、body
	const filterNodeNames = ["HTML", "BODY"];
	// vconsole
	const filterClassNames = ["vc-switch", "vc-mask", "vc-panel"];
	// 模板id 、 vconsole
	const filterIds = [...templateIds, "__vconsole", ...loadingIds];

	let nodes: Element[] = [];
	
  if (document.elementsFromPoint) {
    nodes = document.elementsFromPoint(x, y);
  } else if (document.elementFromPoint) {
    nodes = [document.elementFromPoint(x, y) || document.body];
  }

	const lastNodes = nodes.reduce((acc: Element[], node: Element) => {
		if (
			node?.className?.split(" ")?.indexOf("vc-panel") >= 0 &&
			node?.parentElement?.id === "__vconsole"
		) {
			return [];
		}
		if (
			filterIds.indexOf(node.id) < 0 &&
			filterNodeNames.indexOf(node.nodeName) < 0 &&
			intersect(filterClassNames, node?.className?.split(" "))?.length <= 0
		) {
			acc.push(node);
			return acc;
		}

		return acc;
	}, []);

	if (lastNodes.length > 0) {
		const lastNodeList = lastNodes?.map((item) => ({
			tagName: item?.tagName,
			classNames: item?.className,
			id: item?.id,
		}));
		logger?.info?.({
      position: {x, y},
			lastNodes: safeJSONStringify(lastNodeList),
			description: "[白屏监控] 页面开始渲染",
			eventType: "white-screen-page-rendered-log",
		});
	}

	// console.log(x, y, nodes, lastNodes);
	return lastNodes.length > 0;
}

/**
 * 白屏检测
 * @param total 切割份数
 * @param templates
 * @returns 是否为白屏
 */
export function whiteScreenDetector(total: number, templates: string[]) {
  if (!document.elementsFromPoint && !document.elementFromPoint) {
    logger?.error?.({
      description: "[白屏监控] 浏览器不支持elementFromPoint",
      eventType: "white-screen-element-from-point-not-support",
    });
    return false;
  }
	// 屏幕宽高
	const clientWidth = document.documentElement.clientWidth;
	const clientHeight = document.documentElement.clientHeight;

	// 切割后每份宽高
	const widthUnit = clientWidth / total;
	const heightUnit = clientHeight / total;

  try {
    for (let x = 0, y = 0; x <= total && y <= total; x++, y++) {
      if (checkPoint(x * widthUnit, y * heightUnit, templates)) return false;
      // 横向检测
      for (let xi = x - 1; xi >= 0; xi--) {
        if (checkPoint(xi * widthUnit, y * heightUnit, templates)) return false;
      }
      // 竖向检测
      for (let yj = y - 1; yj >= 0; yj--) {
        if (checkPoint(x * widthUnit, yj * heightUnit, templates)) return false;
      }
    }
  } catch (e) {
    logger?.error?.({
      error: e,
      description: "[白屏监控] 点位检查抛错",
      eventType: "white-screen-element-from-point-error",
    });
    return false;
  }
	return true;
}

export function hookGlobalError() {
	const templateIds = getTemplateId();

	const errorsBeforeLoad: Array<{
		errorInfo: Partial<ErrorEvent> | string;
		reason: string;
	}> = [];
	let isLoaded = false;
	const errorBeforeLoadHandler = () => {
		isLoaded = true;
		errorsBeforeLoad.forEach((error) => {
			logger?.warn?.({
				errorInfo: error.errorInfo,
				description: `[应用挂载前] ${error.reason}`,
				eventType: "white-screen-error-before-load",
			});
		});
	};
	window.addEventListener(ApplicationLoadEventKey, errorBeforeLoadHandler);
	window.addEventListener(ApplicationLoadTimeoutKey, errorBeforeLoadHandler);
	window.addEventListener(
		"error",
		(e) => {
			const errorInfo = isScriptLoadError(e)
				? getSrcFromScriptOnerror(e)
				: {
						error: e?.error?.toString?.(),
						message: e?.message,
						filename: e?.filename,
						lineno: e?.lineno,
						colno: e?.colno,
					};
			const reason = isScriptLoadError(e) ? "页面script加载失败" : "同步Error";
			if (!isLoaded) {
				errorsBeforeLoad.push({
					errorInfo,
					reason,
				});
			} else {
				if (whiteScreenDetector(whiteScreenSliceTotal, templateIds)) {
					// 上报错误
					logger?.error?.({
						errorInfo,
						description: `[应用挂载后] ${reason}导致页面白屏`,
						eventType: "white-screen-error-white-screen-error",
					});
				} else {
					logger?.warn?.({
						errorInfo,
						description: `[应用挂载后] ${reason}触发检查`,
						eventType: "white-screen-error-no-white-screen-error",
					});
				}
			}
		},
		true,
	);
	window.addEventListener("unhandledrejection", (e) => {
		if (whiteScreenDetector(whiteScreenSliceTotal, templateIds)) {
			// 上报错误
			logger?.error?.({
				errorInfo: e,
				description: "[应用挂载后] unhandledrejection导致页面白屏",
				eventType: "white-screen-unhandledrejection-white-screen-error",
			});
		} else {
			logger?.warn?.({
				errorInfo: e,
				description: "[应用挂载后] unhandledrejection事件触发检查",
				eventType: "white-screen-unhandledrejection-no-white-screen-error",
			});
		}
	});
}
