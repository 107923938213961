import { UpgradeYourBrowser } from "../src/browser-compatibility";
import { domainSniffing } from "../src/domain-connection";
import { entryLogger } from "../src/logger";
import { ApplicationLoadEventKey, getDomain } from "../src/utils";
import { hookGlobalError } from "../src/white-screen";

try {
	hookGlobalError();
	domainSniffing(getDomain());
	window.addEventListener("load", () => {
		window.dispatchEvent(new Event(ApplicationLoadEventKey));
		new UpgradeYourBrowser();
	});
} catch (e) {
	entryLogger.error(e);
}
