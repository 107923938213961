import { domainConnectionLogger } from "../logger";
import { getDefaultDomainList, safeJSONStringify } from "../utils";

const domainSniffingSamplingCountKey = "domainSniffingSamplingCount";
const domainSniffingLastFailKey = "domainSniffingLastFail";
const domainSniffingDefaultSamplingRate = 20;
const domainSniffingSamplingRate = window?.ARMS_GLOBAL_CONFIG_SAMPLE ||  domainSniffingDefaultSamplingRate;
const domainSniffingTimeout = window?.domainSniffingTimeout || 10000;

const availabilityErrorType = {
  none: null,
  timeout: "timeout",
  other: "other", // 大概率是cors error
};

type DomainSniffingLogArg = {
	available: boolean;
	responseTime: number;
	errorType: string | null;
};
/**
 * 采样函数
 * @param {number} samplingRate
 * @param {function} callback
 */
function samplingFunction(samplingRate: number, callback: () => void) {
	// 浏览器如果不存在localStorage,则每次都上报
	if (!localStorage) {
		callback();
	}
	const lastFail = localStorage.getItem(domainSniffingLastFailKey) || "true";
	if (lastFail === "true") {
		callback();
		localStorage.setItem(domainSniffingLastFailKey, "false");
		return;
	}
	// 检查本地存储中是否有计数值
	let count =
		Number.parseInt(
			localStorage.getItem(domainSniffingSamplingCountKey) || "",
		) || 0;

	// 判断是否命中采样率
	if (Math.random() < 1 / samplingRate) {
		// 调用callback
		callback();
		// 增加计数值
		count++;

		// 更新本地存储中的计数值
		localStorage.setItem(domainSniffingSamplingCountKey, count.toString());
	}
}

function resultFailCheck(result?: Record<string, DomainSniffingLogArg>) {
  let errorCode = 0;
	for (const key in result) {
		if (result?.[key] && !result?.[key].available) {
      if (result?.[key].errorType !== availabilityErrorType.timeout) {
        errorCode = 2;
        break;
      }
			errorCode = 1;
		}
	}
	return errorCode;
}

function checkDomainAvailability(
	domainList: Array<string>,
	concurrency: number,
	timeout: number,
	callback: (result: Record<string, DomainSniffingLogArg>) => void,
) {
	let currentIndex = 0;
	let activeRequests = 0;
	const results: Record<string, DomainSniffingLogArg> = {};


	function checkNextDomain() {
		if (currentIndex < domainList.length) {
			const currentDomain = domainList[currentIndex];
			currentIndex++;
			const xhr = new XMLHttpRequest();
			xhr.open("GET", `https://${currentDomain}`, true);
			const startTime = performance?.now();
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					const endTime = performance?.now();
					const responseTime = endTime - startTime;
					if (xhr.status === 0) {
						results[currentDomain] = {
							available: false,
							responseTime,
							errorType: availabilityErrorType.other,
						};
					} else {
						results[currentDomain] = {
							available: true,
							responseTime,
							errorType: availabilityErrorType.none,
						};
						activeRequests--;
					}
					checkNextDomain();
				}
			};

			xhr.timeout = timeout;
			xhr.ontimeout = (e) => {
				activeRequests--;
				results[currentDomain] = {
					available: false,
					responseTime: 0,
					errorType: availabilityErrorType.timeout,
				};
				checkNextDomain();
			};

			xhr.onerror = (e) => {
				activeRequests--;
				results[currentDomain] = {
					available: false,
					responseTime: 0,
					errorType: availabilityErrorType.other,
				};
				checkNextDomain();
			};
			activeRequests++;
			xhr.send();
		} else if (activeRequests === 0) {
			callback(results);
		}
	}

	for (let i = 0; i < concurrency; i++) {
		try {
			checkNextDomain();
		} catch (e) {
			// 检测出错可以上报sls
		}
	}
}

function transToLogArg(result: Record<string, DomainSniffingLogArg>) {
	const arr = [];
	for (const key in result) {
		if (result[key]) {
			const item = result[key];
			arr.push({
				domain: key,
				isUsable: item.available,
				duration: item.responseTime || 0,
        errorType: item.errorType
			});
		}
	}
	return arr;
}

export function domainSniffing(
	domainList: Array<string>,
	cb?: (result: Record<string, DomainSniffingLogArg>) => void,
) {
	//校验domainList是否为Array<string>
	if (!Array.isArray(domainList)) {
		return;
	}
	const mergedDomainList = [...domainList, ...getDefaultDomainList()];
	checkDomainAvailability(mergedDomainList, 3, domainSniffingTimeout, (result) => {
    const checkCode = resultFailCheck(result)
		if (checkCode === 2) {
			// 当检查到有失败的域名,则直接上报
			domainConnectionLogger.error({
				description: "域名连通性异常",
				domainInfo: transToLogArg(result),
        eventType: 'domain-connection-failed'
			});
			cb?.(result);
			localStorage.setItem(domainSniffingLastFailKey, "true");
		} else if(checkCode === 1) {
      // 当检查到有失败的域名,则直接上报
			domainConnectionLogger.warn({
				description: "域名连通性存在超时异常",
				domainInfo: transToLogArg(result),
        eventType: 'domain-connection-timeout-failed'
			});
			cb?.(result);
			localStorage.setItem(domainSniffingLastFailKey, "true");
    } else {
			// 成功的域名则采样上报
			samplingFunction(domainSniffingSamplingRate, () => {
				domainConnectionLogger.info({
					description: "域名连通性正常",
					domainInfo: transToLogArg(result),
          eventType: 'domain-connection-success'
				});
			});
			cb?.(result);
		}
	});
}
