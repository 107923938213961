import { EWTLogger } from "@ewt/sls-web-track/lib";
import { EnumOfTopic } from "@ewt/sls-web-track/lib/meta-info";

EWTLogger?.setGlobalProps({
  // eslint-disable-next-line no-underscore-dangle
  release: window?.__MST_LABEL__ || 'baseline',
});

export const entryLogger = new EWTLogger(EnumOfTopic.HighAvailability, "entry");
export const browserCompatibilityLogger = new EWTLogger(
	EnumOfTopic.HighAvailability,
	"browser-compatibility",
);
export const domainConnectionLogger = new EWTLogger(
	EnumOfTopic.HighAvailability,
	"domain-connection",
);
export const whiteScreenLogger = new EWTLogger(
	EnumOfTopic.HighAvailability,
	"white-screen",
);
export const poorNetworkLogger = new EWTLogger(
	EnumOfTopic.HighAvailability,
	"poor-network",
);
